import axios from "axios"
import router from "../router";

const token = localStorage.getItem("token");
// php api
const mainAxios = axios.create({
    baseURL: process.env.VUE_APP_PHPAPI
});

mainAxios.interceptors.request.use(
    function(config) {
      if (
        localStorage.getItem("token") === null ||
        localStorage.getItem("user") === null
      ) {
        config.headers.Authorization = "";
        mainAxios.defaults.headers.common["Authorization"] = "";
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
);

let autoLogoutTimeHandler;
mainAxios.interceptors.response.use(
  function(response) {
    window.clearTimeout(autoLogoutTimeHandler);
    autoLogoutTimeHandler = setTimeout(autoLogout, 10800000);
    return response;
  },
  function(error) {
    if(error.response.status === 401  &&  router.currentRoute.path == '/auth/login'){
      return Promise.reject(error);
    }else if (error.response.status === 401 || error.response === 500  ) {
      localStorage.clear();
      router.push("/auth/login");
      return Promise.reject(error);
    }
  }
);


if (token) {
    mainAxios.defaults.headers.common["Authorization"] = token;
}

// node api
const customAxios = axios.create({
   baseURL: process.env.VUE_APP_NODEAPI
});


customAxios.interceptors.request.use(
    function(config) {
      if (
        localStorage.getItem("token") === null ||
        localStorage.getItem("user") === null
      ) {
        config.headers.Authorization = "";
        customAxios.defaults.headers.common["Authorization"] = "";
      }
      
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
);


let autoLogoutTimeHandler1;
customAxios.interceptors.response.use(
  function(response) {
    window.clearTimeout(autoLogoutTimeHandler1);
    autoLogoutTimeHandler1 = setTimeout(autoLogout, 10800000);
    return response;
  },
  function(error) {
    if(error.response.status === 401  &&  router.currentRoute.path == '/auth/login'){
      return Promise.reject(error);
    }else if (error.response.status === 401 || error.response === 500  ) {
      localStorage.clear();
      router.push("/auth/login");
      return Promise.reject(error);
    }
  }
);


if (token) {
    customAxios.defaults.headers.common["Authorization"] = token;
}


function autoLogout() {
    alert("Session Expired");
    localStorage.clear();
    router.push("/auth/login");
}
  

export {
  mainAxios,
  customAxios
};
import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "../views/Layout.vue";
import Login from "../views/auth/Login.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "transaction",
    redirect: "/transaction",
    component: AppLayout,
    children: [
      {
        path: "transaction",
        name: "transaction",
        component: () => import("../views/pages/transaction.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      
      // {
      //   path: "deals",
      //   name: "deals",
      //   component: () => import("../views/DealsPage.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: 'deals',
      //   name: 'deals',
      //   component: () => import( '../views/Deals.vue'),
      // },
      {
        path: "change-password",
        name: "change-password",
        component: () => import("../views/pages/changePassword.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "contact-us",
      //   name: "contact-us",
      //   component: () => import("../views/pages/ContactUs.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
     
      // {
      //   path: "deposit-request",
      //   name: "deposit-request",
      //   component: () => import("../views/pages/deposit/DepositRequest.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: "deposits",
      //   name: "deposits",
      //   component: () => import("../views/pages/deposit/DepositList.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: "request/credit",
      //   name: "credit-request",
      //   component: () => import("../views/pages/request/credit.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     access:{
      //       credit_request : true
      //     }
      //   },
      // },
      {
        path: "request/cancellation",
        name: "cancellation-request",
        component: () => import("../views/pages/request/cancellation.vue"),
        meta: {
          requiresAuth: true
        },
      },
      // {
      //   path: "profile",
      //   name: "profile",
      //   component: () => import("../views/pages/UserProfile.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "transaction",
        name: "transaction",
        component: () => import("../views/pages/transaction.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "search",
      //   name: "search",
      //   component: () => import("../views/Search.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: "booking",
      //   name: "boking",
      //   component: () => import("../views/Booking.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "ticket/:id",
        name: "ticketView",
        component: () => import("../views/pages/Confirmation.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "bank-details",
      //   name: "bank-details",
      //   component: () => import("../views/pages/bankDetails.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "group-query",
        name: "group-query",
        component: () => import("../views/pages/GroupQuery.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "my-booking",
        name: "my-booking",
        component: () => import("../views/pages/bookingList.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "view/:id",
        name: "view",
        component: () => import("../views/pages/viewTicket.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/print",
    name: "printLayout",
    component: () => import("../views/Layouts/PrintLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        name: "print",
        component: () => import("../views/pages/ticketPrint.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/auth",
    name: "Authentication",
    redirect: "/auth/login",
    component: () => import("../views/auth/Layout.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          guest: true,
        },
      },
      // {
      //   path: "register",
      //   name: "Register",
      //   component: () => import("../views/auth/Register.vue"),
      //   meta: {
      //     guest: true,
      //   },
      // },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta: {
          guest: true,
        },
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta: {
          guest: true,
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: () => import("../views/auth/ChangePassword.vue"),
        meta: {
          guest: true,
        },
      },
    ],
  },
  // {
  //   path: "/thank",
  //   name: "Thank",
  //   component: () => import("../views/auth/Thank.vue"),
  //   meta: {
  //     guest: true,
  //   },
  // },
  {
    path: "/privacy-policy",
    name: "privacypolicy",
    component: () => import("../views/pages/privacy-policy.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import("../views/pages/terms-and-conditions.vue"),
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
 // base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth))
  {
    if (store.getters.isLoggedIn) {
      if (to.name === "credit-request" && store.state.user.credit_request_status == 0) {
          next({ path: '/'}) ;
      }
      next();
      return;
    }
    next("/auth/login");
  } else {
    next();
  }
});

export default router;

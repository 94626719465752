<template>
  <div>
    <BlockUI :message="msg" :html="html" v-if="loading"></BlockUI>
    <div class="alert alert-info text-uppercase font-weight-bold clickhere" role="alert">
      <p>For flight search please
        <a href="https://agent.goflysmart.com/" target="_blank">click here</a>
      </p>
    </div>
     <ValidationObserver v-slot="{ invalid }" ref="form" >
      <form action class="mb-2" @submit.prevent="submitLoginForm">
        <h5 class="text-black mb-1">Login</h5>
        <div class="mb-2">
          <p class="text-danger" >
              <ul v-if="errors.message">
                <li>{{ errors.message }}</li>
              </ul> 
          </p>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="{ errors }">
          <input type="text" placeholder="Username" class="form-control" v-model="form.email" required />
          <span class="colorRed">{{ errors[0] }}</span>
              </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="{ errors }">
          <input type="password" placeholder="Password" class="form-control" v-model="form.password" required />
          <span class="colorRed">{{ errors[0] }}</span>
              </ValidationProvider>
        </div>
        <button class="btn btn-block btn-primary text-center text-uppercase" type="submit" :disabled="invalid">Log In</button>
      </form>
     </ValidationObserver>
      <div class="row">
        <div class="col-6">
          <router-link :to="{name: 'ForgotPassword'}">Forgot Password?</router-link>
        </div>
        <!-- <div class="col-6 text-right">
          <router-link :to="{name: 'Register' }" class="text-black">Register Now</router-link>
        </div> -->
      </div>
  </div>
</template>
<script>
import loadingImage from "@/assets/loading.gif";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      msg: "Processing your request .. ",
      html: `<img src="${loadingImage}" width="100px"> `,
      form: {
        email: "",
        password: "",
      },
      errors: [],
    };
  },
  methods: {
    submitLoginForm() {
      this.loading = true;
      this.$store
        .dispatch("login", this.form)
        .then(() => {
          this.loading = false;
          this.$router.push("/transaction");
        })
        .catch((err) => {
          this.loading = false;
          this.errors = err.response.data;
        });
    },
  },
};
</script>
<style>
.clickhere a {
  text-decoration: underline !important;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : JSON.parse(localStorage.getItem('user')) || ''
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, payload){
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error(state,err){
      state.status = err
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    setEmailVerified(state) {
      state.user.isEmailVerified = 1
      state.user.isPhoneVerified = 1
    }
  },
  actions: {
  updateUserDetails({commit}){
    commit('setEmailVerified')
  },
  login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')        
        nodeAxios.post('auth/login',{
          username: user.email,
          password: user.password
        })
        .then(resp => {
          const token = resp.data.access_token
          const user   = resp.data.user;
       
          const payload = {
            token: token,
            user:user
          };
         
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          axios.defaults.headers.common['Authorization'] = token
          nodeAxios.defaults.headers.common["Authorization"] = token
          commit('auth_success',payload)
          resolve(resp)
        })
        .catch(err => {
          console.log(err);
          
          commit('auth_error',err)
          localStorage.removeItem('token')
          reject(err)
        })
      })
  },

  logout({commit}){
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization']
      delete nodeAxios.defaults.headers.common["Authorization"]
      resolve()
    })
  }
  },
  modules: {
  }
})

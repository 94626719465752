import Vue from "vue";
import App from "./App.vue";
import { mainAxios,
  customAxios} from './configs/axios.config'
import router from "./router";
import store from "./store";
// import Rollbar from 'rollbar';
import Autocomplete from "@trevoreyre/autocomplete-vue";
import BlockUI from "vue-blockui";

import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect.VueSelect);

// Import Bootstrap an BootstrapVue CSS files (order is important)

import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(require("vue-moment"));

// Vue.prototype.$rollbar = new Rollbar({
//   accessToken: 'a390bb4b79264936ba71eff97a092668',
//   captureUncaught: true,
//   captureUnhandledRejections: true,
// });

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
// Vue.config.errorHandler = (err, vm, info) => {
//   vm.$rollbar.error(err);
//   throw err; // rethrow
// };

// Add the required rule
extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "This field must be a valid email",
});

extend("contactPerson", {
  validate: (value) => {
    return value.indexOf(" ") >= 0 ? true : false;
  },
  message: "Enter the first and last name",
});

extend("checkPhoneNumber", {
  validate: (value) => {
    return !isNaN(value) & (value.length == 10) ? true : false;
  },
  message: "Enter a valid phone number",
});

const isEmailUnique = (value) => {
  return axios.post("validate/email", { email: value }).then((response) => {
    return {
      valid: response.data.success,
      data: {
        message: response.data.message,
      },
    };
  });
};

const isPhoneUnique = (value) => {
  return axios.post("validate/phone", { phone: value }).then((response) => {
    return {
      valid: response.data.success,
      data: {
        message: response.data.message,
      },
    };
  });
};

// The messages getter may also accept a third parameter that includes the data we returned earlier.
extend("uniqueEmail", {
  validate: isEmailUnique,
  message: "Email is registered",
});

extend("uniquePhone", {
  validate: isPhoneUnique,
  message: "Phone number is registered",
});

Vue.use(BlockUI);

Vue.use(Autocomplete);

// add these before Vue is instantiated
window.axios = mainAxios;
window.nodeAxios = customAxios;
// axios.defaults.baseURL = 'https://api.goflysmart.com/api/'
// axios.defaults.baseURL = "https://goflysmartapi-pre-prod1.azurewebsites.net/api/";
// axios.defaults.baseURL = 'http://goflysmartadminapi.test/api/'
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
// axios.defaults.baseURL = 'http://192.168.29.139:8000/api/'
// axios.interceptors.request.use(
//   function(config) {
//     if (
//       localStorage.getItem("token") === null ||
//       localStorage.getItem("user") === null
//     ) {
//       config.headers.Authorization = "";
//       axios.defaults.headers.common["Authorization"] = "";
//     }

//     return config;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );
// let autoLogoutTimeHandler;
// axios.interceptors.response.use(
//   function(response) {
//     window.clearTimeout(autoLogoutTimeHandler);
//     autoLogoutTimeHandler = setTimeout(autoLogout, 10800000);
//     return response;
//   },
//   function(error) {
//     if(error.response.status === 401  &&  router.currentRoute.path == '/auth/login'){
//       return Promise.reject(error);
//     }else if (error.response.status === 401 || error.response === 500  ) {
//       localStorage.clear();
//       router.push("/auth/login");
//       return Promise.reject(error);
//     }
//   }
// );

// function autoLogout() {
//   alert("Session Expired");
//   localStorage.clear();
//   router.push("/auth/login");
// }

// const token = localStorage.getItem("token");
// if (token) {
//   axios.defaults.headers.common["Authorization"] = token;
// }


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
    <header class="header">
        <div class="container">
            <div class="header-top">
                <nav class="navigation">
                    <ul class="navigation-list text-capitalize">
                        <li>
                            <a href="tel:918389898989">Support No &nbsp;<i class="fa fa-phone fa-rotate-90" ></i>&nbsp;&nbsp;83 89 89 89 89 </a>
                        </li>
                        <!-- <li>
                            <a
                                :href="
                  'https://payments.goflysmart.com?agent_id=' +
                  this.$store.state.user.code +'&token='+this.$store.state.token.split(' ')[1]
                "
                            >
                                Online Recharge
                            </a>
                        </li> -->

                        <!-- <li>
                            <router-link to="/contact-us">Contact Us </router-link>
                        </li> -->
                        <li>
                            <a href="javascript:void(0)" @click="logout">Logout</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="container">
                <div class="header-mid py-1 text-right">
                    <div class="row">
                        <div class="col-md-3 text-left">
                            <a href="/" class="header-logo d-block logo">
                                <img src="/img/base/logo.png" class="mt-3 img-responsive" alt />
                            </a>
                        </div>
                        <div class="col-md-9">
                            <h6 class="text-black">
                                Credit Shell:
                                <span class="text-primary">
                                    Rs. {{ this.$store.state.user.credit_shell }}
                                </span>
                                Credit Limit:
                                <span class="text-primary">
                                    Rs. {{ this.$store.state.user.credit_limit }}
                                </span>
                                Credit Balance:
                                <span class="text-primary">
                                    Rs. {{ this.$store.state.user.credit_balance }}
                                </span>

                              
                                <br class="break" />

                                <span :style="{ marginLeft: '10px' }"> Balance:</span>
                                <span class="text-primary" :style="{ marginLeft: '5px' }">
                                    Rs. {{ this.$store.state.user.opening_balance }}
                                </span>
                                <small @click="getAgentDetails" :style="{ marginLeft: '5px' }"><i class="fa fa-sync fa-1x text-black"></i></small>

                            </h6>

                            <p class="font-weight-bold mt-1 text-black">
                                Welcome {{ this.$store.state.user.company_name }}, {{ this.$store.state.user.code }} 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="header-bottom">
                    <div class="row">
                        <div class="col-md-4 offset-md-1 align-items-end d-flex col-8">
                        </div>
                        <div class="d-flex flex-row-reverse align-items-center col-md-7 text-right col-4">
                            <div class="theme-dropdown h-100 text-center">
                                <a class="h-100" style="padding: 8px 15px; font-size: 0.9rem; font-weight: 400;" href="javascript:void(0)">My Accounts</a>
                                <ul class="theme-dropdown-menu">
                                    <li>
                                        <router-link to="/my-booking">My Bookings</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/transaction">My Transaction</router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link to="/bank-details">Bank Details</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/deposits">Deposits Request</router-link>
                                    </li>
                                    <li v-if="this.$store.state.user.credit_request_status">
                                      <router-link to="/request/credit">Credit Request</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/request/cancellation">Cancel Request</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/contact-us">Contact Us</router-link>
                                    </li> -->
                                    <!-- <li>
                                        <a :href="'https://payments.goflysmart.com?agent_id=' +this.$store.state.user.code">Online Recharge</a>
                                    </li> -->
                                    <!-- <li>
                                        <router-link to="/profile">Profile</router-link>
                                    </li> -->
                                    <li>
                                        <router-link to="/change-password">Change Password</router-link>
                                    </li>
                                </ul>
                            </div>

                            <!-- <router-link to="/deals" data-form="roundTrip" class="btn-secondary h-100 text-center" style="padding: 8px 15px; font-size: 0.9rem; font-weight: 400;">Hot Deals</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
    export default {
        mounted() {
            this.getAgentDetails();
        },
        data() {
            return {
                load: false,
            };
        },
        methods: {
            logout() {
                this.$store
                    .dispatch("logout", this.form)
                    .then(() => this.$router.push("/auth/login"))
                    .catch((err) => (this.errors = err.response.data));
            },
            getAgentDetails() {
                this.load = true;

                axios
                    .get("me")
                    .then((resp) => {
                        this.load = false;
                        var user = resp.data.data;
                        this.$store.state.user.opening_balance = resp.data.data.opening_balance;
                        this.$store.state.user.credit_balance = resp.data.data.credit_balance;
                        this.$store.state.user.credit_request_status = resp.data.data.credit_request_status;
                        localStorage.setItem("user", JSON.stringify(user));
                    })
                    .catch((err) => {
                        this.load = false;
                        console.log(err);
                    });
            },
        },
    };
</script>
<style>
    .header-top {
        background-color: #0f3f5a;
    }

    .header-top .navigation > .navigation-list > li {
        background: no-repeat;
        border: 0;
    }

    .header-top .navigation > .navigation-list > li > a {
        color: #ffff !important;
        padding: 4px 8px;
        font-size: 0.75rem;
    }

    .header-top .navigation > .navigation-list > li > a:hover {
        background-color: #ee8622;
    }

    .onlinepayment {
        animation: color-change 1s infinite;
    }
    @keyframes color-change {
        0% {
            color: red;
        }
        50% {
            color: blue;
        }
        100% {
            color: red;
        }
    }

    @media only screen and (max-width: 600px) {
        .logo {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 10px !important;
        }
    }

    @media only screen and (min-width: 450px) {
        .break {
            display: none;
        }
    }
</style>